import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import { Vue } from "vue-class-component";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/agenda",
        name: "agenda",
        component: () => import("@/components/Agenda.vue"),
        props: true,
    },
    {
        path: "/staking",
        name: "staking",
        component: () => import("@/components/Staking.vue"),
    },
    {
        path: "/agendadetail",
        name: "agendadetail",
        component: () => import("@/components/AgendaDetail.vue"),
        props: true,
    },
    {
        path: "/forum",
        name: "forum",
        component: () => import("@/components/Forum.vue"),
        props: true,
    },
    {
        path: "/forumdetail",
        name: "forumdetail",
        component: () => import("@/components/ForumDetail.vue"),
        props: true,
    },
    {
        path: "/foruminfo",
        name: "foruminfo",
        component: () => import("@/components/ForumInfo.vue"),
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const originalPush = createRouter.prototype.push;
createRouter.prototype.push = function push(location: any) {
    return originalPush.call(location).catch(() => {
        return window.location.reload();
    });
};

export default router;
