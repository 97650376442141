
import { Options, Vue } from "vue-class-component";
import { useI18n, LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "../store";

import { ethers, Signer } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import PopupOk from "@/components/PopupOk.vue";
import Service from "@/services";
import Mixin from "../mixin";

@Options({
    components: {
        PopupOk,
    },
    props: {
        initConnected: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        initConnected(connect: boolean) {
            if (connect && this.ethereum) {
                this.provider = new ethers.providers.Web3Provider(this.ethereum, "any");
                this.connectMetaMask();
            } else {
                this.metaMaskInstall();
            }
        },
    },
})
export default class Header extends Vue {
    i18n = useI18n();
    router = useRouter();
    store = useStore();

    initIsBlack = false;
    isWalletType = 0;
    walletInfo = false;
    currentLanguage = "EN";
    foldLang = false;
    foldLang2 = false;
    foldHeader = false;
    langs = [
        {
            title: "KR",
            code: "kr",
        },
        {
            title: "EN",
            code: "en",
        },
    ];

    popupOkShow = false;
    popupOkHeader = "";
    popupOkMessage = "";
    popupOkCallBack = "";

    CallbakHeader = "";
    onAccountsChangedProcess = false;
    /**
     * ethereum
     */
    // ethereum = window.klaytn;
    ethereum = window.ethereum;
    provider: Web3Provider | null = null;
    signer: Signer | undefined = undefined;

    interval: any;

    async created() {
        if (Object.keys(this.store.state.translation.en).length === 0) {
            await this.translationFn();
        } else {
            await this.setI18n(this.store.state.translation);
        }
        await this.configFront();

        if (this.ethereum) {
            this.provider = new ethers.providers.Web3Provider(this.ethereum, "any");
            this.provider.on("network", (newNetwork, oldNetwork) => {
                if (oldNetwork) {
                    this.popupOkShow = true;
                    this.popupOkHeader = this.i18n.t("header.metamask");
                    this.popupOkMessage = this.i18n.t("header.popup_network_change");
                    this.popupOkCallBack = "reload";
                } else if (Number(newNetwork.chainId) !== Number(this.store.state.metaMask.chainId)) {
                    this.popupOkShow = true;
                    this.popupOkHeader = this.i18n.t("header.metamask");
                    this.popupOkMessage = this.i18n.t("header.popup_klaytnNetworkOnly");
                    this.popupOkCallBack = "change";
                }
            });

            this.ethereum.on("accountsChanged", async (accounts: any) => {
                if (accounts.length === 0 || this.popupOkShow === true || this.onAccountsChangedProcess === true) return;

                this.onAccountsChangedProcess = true;

                const account = accounts[0];
                // 메타마스크 첫 로그인할때는 팝업 안띄우게
                if (this.store.state.address !== "" && this.store.state.address !== account) {
                    this.popupOkShow = true;
                    this.popupOkHeader = this.i18n.t("header.metamask");
                    this.popupOkMessage = this.i18n.t("header.popup_account_change");
                    this.popupOkCallBack = "reload";

                    this.store.commit("setAddress", "");
                    this.store.commit("setReloadFlag", true);
                }

                this.onAccountsChangedProcess = false;
            });
        } else {
            this.store.commit("setAllReset");
        }

        const isLanguage = this.store.state.isLanguage.toString();
        if (typeof this.store.state.isLanguage === "object") {
            this.store.commit("setLanguage", this.store.state.isLanguage.code);
        } else if (isLanguage === "") {
            const navigatorLanguage = navigator.language.substring(0, 2) === "ko" ? "kr" : navigator.language.substring(0, 2);
            this.changeLanguage({
                title: navigatorLanguage.toUpperCase(),
                code: navigatorLanguage,
            });
        } else {
            this.changeLanguage({
                title: isLanguage.toUpperCase(),
                code: isLanguage,
            });
        }

        if (this.store.state.address === "") {
            this.store.commit("setConnected", false);

            if (this.store.state.reloadFlag) {
                this.connectMetaMask();
                this.store.commit("setReloadFlag", false);
            }
        } else {
            // this.setSigner();
        }

        if (this.store.state.metaMask !== null && this.store.state.metaMask.chainId === "") {
            return;
        }
    }

    /**
     * Governance Info
     */
    async configFront() {
        const responses = await Service.configFront();
        if (responses.data.returnCode === 0) {
            const data = responses.data.configFrontVOForApi;
            this.store.commit("setConfigFront", data);

            if (data.translationVersion !== this.store.state.translationVersion) {
                await this.translationFn();
            }
        }
    }

    async translationFn() {
        const param = {
            version: this.store.state.translationVersion,
        };

        const responses = await Service.translation(param);
        if (responses.data.returnCode === 0) {
            const data = responses.data;
            this.store.commit("setTranslation", data);
            this.setI18n(data.data);
        }
    }

    setI18n(data: any) {
        Object.keys(data).forEach((lang) => {
            let index = this.langs.find((e) => {
                return e.code === lang;
            });
            if (index === undefined) {
                this.langs.push({
                    title: lang.toUpperCase(),
                    code: lang,
                });
            }

            this.i18n.setLocaleMessage(lang, JSON.parse(JSON.stringify(data[lang])) as any);
        });
    }

    connectChecked(type: string) {
        if (type === "/agenda" || this.store.state.isConnected) {
            this.router.push({
                path: type,
            });
            this.foldHeader = false;
        } else {
            this.connectMetaMask();
        }
    }

    mounted() {
        if (document.documentElement.scrollTop > 0) this.handleScroll();
        window.addEventListener("scroll", this.handleScroll);

        this.store.state.emitter.on("isSignature", async (r: any) => {
            this.popupOkShow = r.isSignature;
            this.popupOkHeader = this.i18n.t("message.signature");
            this.popupOkMessage = this.i18n.t("header.signature_expired");
            this.popupOkCallBack = "setSign";
        });

        /**
         * 티켓 연장용 Interval
         */
        // this.interval = setInterval(async () => {
        //     if (this.store.state.signature) {
        //         await Service.userTicketCheck({
        //             id: this.store.state.signature.id,
        //             sign: this.store.state.signature.sign,
        //         });
        //     }
        // }, (this.store.state.configFront.ticketTime - 10) * 1000);
        if (this.store.state.isConnected) {
            this.store.commit("setIntervalTimer");
        }
    }

    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);

        // clearInterval(this.interval);
        this.store.commit("clearIntervalTimer");
    }

    handleScroll() {
        if (document.documentElement.scrollTop > 0) {
            this.initIsBlack = true;
        } else {
            this.initIsBlack = false;
        }
    }

    changeLanguage(item: any) {
        if (
            this.langs.find((e) => {
                return e.code === item.code;
            }) === undefined
        ) {
            console.error("invalid item", item);
            return;
        }
        this.currentLanguage = item.title.toString();
        this.i18n.locale = item.code;
        this.foldLang = false;
        this.foldLang2 = false;
        this.foldHeader = false;

        this.store.commit("setLanguage", item.code);
        this.store.state.emitter.emit("changeLanguage", {
            isSearched: true,
        });
    }

    clickLogo() {
        window.scrollTo(0, 0);
        this.$router.push("/");
        this.foldHeader = false;
    }

    close(popupType: string) {
        switch (popupType) {
            case "PopupOk":
                this.popupOkShow = false;
                this.popupOkHeader = "";
                this.popupOkMessage = "";
                if (this.popupOkCallBack === "reload") {
                    location.reload();
                } else if (this.popupOkCallBack === "change") {
                    this.switchChain();
                } else if (this.popupOkCallBack === "main") {
                    this.$router.push("/");
                } else if (this.popupOkCallBack === "setSign") {
                    // this.setWalltInfo(this.store.state.address);
                    this.setSigner();
                } else if (this.popupOkCallBack === "disconnect") {
                    this.store.commit("setAddress", "");
                    this.store.commit("setConnected", false);
                    this.store.commit("clearIntervalTimer");
                    this.walletInfo = false;
                    this.$router.push("/");
                }

                break;
        }
    }

    /**
     * Wallet
     */
    async switchChain() {
        let chainId = "";
        let params: any[] = [];

        try {
            chainId = ethers.utils.hexlify(Number(this.store.state.metaMask.chainId));
            chainId = ethers.utils.hexStripZeros(chainId);
            params = [
                {
                    chainId: chainId,
                    chainName: this.store.state.metaMask.networkName,
                    nativeCurrency: {
                        name: this.store.state.metaMask.symbol,
                        symbol: this.store.state.metaMask.symbol,
                        decimals: 18,
                    },
                    rpcUrls: [this.store.state.metaMask.url],
                    blockExplorerUrls: [this.store.state.metaMask.scanEndpoint],
                },
            ];

            await this.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }],
            });
        } catch (switchError: any) {
            if (switchError.code === 4902 || switchError.code === -32603) {
                try {
                    await this.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: params,
                    });
                } catch (addError: any) {
                    this.popupOkShow = true;
                    this.popupOkHeader = this.i18n.t("header.metamask");
                    this.popupOkMessage = addError.message;
                    this.popupOkCallBack = "";
                }
            } else if (switchError.code === -32002) {
                this.popupOkShow = true;
                this.popupOkHeader = this.i18n.t("header.metamask");
                this.popupOkMessage = this.i18n.t("header.popup_request_already");
                this.popupOkCallBack = "";
            } else {
                this.popupOkShow = true;
                this.popupOkHeader = this.i18n.t("header.metamask");
                this.popupOkMessage = switchError.message;
                this.popupOkCallBack = "";
            }
        }
    }

    /**
     * 메타마스크 연결
     */
    async connectMetaMask() {
        if (this.store.state.isConnected) {
            if (Mixin.isPc()) this.walletInfo = !this.walletInfo;
            else this.disconnectWallet();
            return;
        }

        if (this.ethereum) {
            // 메타마스크 설치
            // MetaMask 연결 및 계정 가져오기
            try {
                await this.provider?.send("eth_requestAccounts", []);
                this.setSigner();
            } catch (e: any) {
                this.store.commit("setConnected", false);
                this.popupOkShow = true;
                this.popupOkHeader = "MetaMask";
                this.popupOkMessage = e.message;
                this.popupOkCallBack = "";
            }
        } else {
            // 메타마스크 미설치
            this.metaMaskInstall();
        }
    }

    metaMaskInstall() {
        this.popupOkShow = true;
        this.popupOkHeader = "Connection Error";
        this.popupOkMessage = "MetaMask is not installed.<br/><a href='https://metamask.io/download.html' target='_blank' class='metamask_download'>Install MetaMask for your browser.</a>";
        this.popupOkCallBack = "reload";
    }

    /**
     * Set Signer Address
     */
    async setSigner() {
        try {
            this.signer = await this.provider?.getSigner();
            const addr = await this.signer?.getAddress();
            if (addr !== this.store.state.address) {
                await this.setWalltInfo(addr);
            }
        } catch (e: any) {
            let message = String(e);
            if (e.code === "UNSUPPORTED_OPERATION") {
                message = this.i18n.t("header.wallet_locked");
                this.disconnectWallet();
            }

            this.store.commit("setConnected", false);
            this.popupOkShow = true;
            this.popupOkHeader = "MetaMask";
            this.popupOkMessage = message;
            this.popupOkCallBack = "main";
        }
    }

    async setWalltInfo(addr: any) {
        if (this.signer === undefined) {
            return;
        }

        await this.checkUserSignature(addr);
    }

    /**
     * 메타마스크 사인 지갑 연결
     */
    async checkUserSignature(addr: any) {
        const returnSignature = await this.signature();
        if (returnSignature && returnSignature.valid) {
            const param = {
                address: addr,
                signature: returnSignature.signature,
                message: returnSignature.message,
            };

            try {
                const responses = await Service.userSignature(param);
                const respData = responses.data;
                if (respData.returnCode === 0) {
                    this.store.commit("setSignature", {
                        signature: returnSignature.signature,
                        message: returnSignature.message,
                        id: respData.id,
                        sign: respData.sign,
                    });
                    this.store.commit("setAddress", addr);
                    this.store.commit("setIntervalTimer");

                    if (this.$router.currentRoute.value.path === "/staking") {
                        this.store.state.emitter.emit("stakingSearch", {
                            isSearched: true,
                        });
                    } else if (this.$router.currentRoute.value.path === "/agendadetail") {
                        this.store.state.emitter.emit("agendaDetailSearch", {
                            isSearched: true,
                        });
                    } else if (this.$router.currentRoute.value.path === "/agenda") {
                        this.store.state.emitter.emit("agendaSearch", {
                            isSearched: true,
                        });
                    }
                }
            } catch (e: any) {
                this.store.commit("setSignature", {
                    signature: "",
                    message: "",
                    id: "",
                    sign: "",
                });
            }
        }
    }

    async signature() {
        const message = "Governance Signature(" + Date.now().toString() + ")";
        try {
            const signature = await this.signer?.signMessage(message);
            return {
                valid: true,
                signature: signature,
                message: message,
            };
        } catch (e) {
            this.disconnectWallet();

            return {
                valid: false,
                signature: null,
                message: null,
            };
        }
    }

    onCopySuccess() {
        this.popupOkShow = true;
        this.popupOkHeader = this.i18n.t("header.metamask");
        this.popupOkMessage = this.i18n.t("header.copy_success");
        this.popupOkCallBack = "";
    }

    onCopyError(e: any) {
        this.popupOkShow = true;
        this.popupOkHeader = this.i18n.t("header.metamask");
        this.popupOkMessage = String(e);
        this.popupOkCallBack = "reload";
    }

    viewOnExplorer() {
        window.open(this.store.state.configFront.klayScanUrl + "account/" + this.store.state.address);
    }

    disconnectWallet() {
        this.popupOkShow = true;
        this.popupOkHeader = this.i18n.t("header.metamask");
        this.popupOkMessage = this.i18n.t("header.disconnect_wallet");
        this.popupOkCallBack = "disconnect";
    }
}
