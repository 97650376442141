
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import KeyVisual from "@/components/KeyVisual.vue";
import PopupShow from "@/components/PopupShow.vue";
import PopupOk from "@/components/PopupOk.vue";
import Service from "@/services";
import Mixin from "../mixin";
import dayjs from "dayjs";

import { Options, Vue } from "vue-class-component";
import { useI18n } from "vue-i18n";
import { useStore } from "../store";
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { ethers, Signer } from "ethers";
import { Web3Provider } from "@ethersproject/providers";

Chart.register(...registerables);
@Options({
    components: {
        LineChart,
        Header,
        Footer,
        KeyVisual,
        PopupShow,
        PopupOk,
    },
})
export default class Staking extends Vue {
    i18n = useI18n();
    store = useStore();

    stakingWeekTotal = 0;
    stakingWeekData: any;
    chartData: any = {};
    chartOptions: any = {};

    stakingStateData: any = {
        accumulateRewardPoint: 0,
        rewardPoint: 0,
        stakingTotal: 0,
        total: 0,
        votePower: 0,
        withdrawAmount: 0,
    };
    stakingInfoData: any = {
        apr: 0,
        period: 0,
        depositAddress: "",
        amount: "0",
        minAmount: 0,
    };

    stakingAmount = "0";
    numberStakingAmount = 0;
    unStakingAmount = "0";
    numberUnStakingAmount = 0;

    stakingHistory: any[] = [];
    progressList: any[] = [];
    currentPageNo = 1; // 현재 페이지 번호
    currentPageSize = 6; // 한 번에 불러올 게시물 수
    pageCount = 0; // 전체 페이지 수

    popupShow = false;
    popupShowHeader = "";
    popupShowMessage = "";
    popupShowData = "";
    popupShowType = "";
    popupShowCallBack = "";

    popupOkShow = false;
    popupOkHeader = "";
    popupOkMessage = "";
    popupOkCallBack = "";

    provider: Web3Provider | null = null;
    signer: Signer | undefined = undefined;

    isPressedBtn = false;

    intervalCountDownTimer: any;
    now = 0;

    async created() {
        this.provider = await new ethers.providers.Web3Provider(window.ethereum, "any");
        await this.provider.send("eth_requestAccounts", []);
        this.signer = await this.provider.getSigner();

        if (this.store.state.isConnected) {
            await this.allSearch();
        }

        this.intervalCountDownTimer = setInterval(() => {
            this.countDownTimer();
        }, 1000);
    }

    async allSearch() {
        await this.stakingWeek();
        await this.stakingState();
        await this.stakingInfo();
        await this.userStaking();
    }

    mounted() {
        this.store.state.emitter.on("stakingSearch", async (r: any) => {
            if (r.isSearched) {
                this.listRefresh();
                await this.allSearch();
            }
        });
    }

    unmounted() {
        this.now = 0;
        clearInterval(this.intervalCountDownTimer);
    }

    async stakingWeek() {
        const responses = await Service.stakingWeek();
        if (responses.data.returnCode === 0) {
            this.stakingWeekTotal = responses.data.total;

            let data = [],
                labels = [];
            for (const i in responses.data.list) {
                const d = responses.data.list[i];
                data.push(Number(d.stakingAmount));
                labels.push(Mixin.getLocalizedDayString(d.date));
            }

            data.push(Number(this.stakingWeekTotal));
            labels.push("Current");

            this.setChartData(data, labels);
        } else {
            switch (responses.returnCode) {
                default: {
                    let dataObj = {
                        that: this,
                        title: "stakingWeek",
                        msg: responses,
                        callback: "",
                    };
                    this.store.commit("printError", dataObj);
                    break;
                }
            }
        }
    }

    setChartData(data: any, labels: any) {
        this.chartData = {
            datasets: [
                {
                    backgroundColor: "#a9c6f2",
                    data: data,
                    fill: true,
                    label: "ATT",
                    pointRadius: 0,
                    showLine: false,
                },
            ],
        };

        this.chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            scales: {
                x: {
                    labels: labels,
                    title: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    title: {
                        display: false,
                    },
                },
            },
        };
    }

    async stakingState() {
        const param = {
            address: this.store.state.address,
            id: this.store.state.signature.id,
            sign: this.store.state.signature.sign,
        };

        const responses = await Service.userStakingState(param);
        const respData = responses.data;
        if (respData.returnCode === 0) {
            this.stakingStateData = {
                accumulateRewardPoint: respData.accumulateRewardPoint,
                rewardPoint: respData.rewardPoint,
                stakingTotal: respData.stakingTotal,
                total: respData.total,
                votePower: Math.floor(respData.votePower),
                withdrawAmount: respData.withdrawAmount,
            };
        } else {
            respData.that = this;
            respData.title = "stakingState";
            Mixin.responsesManager(respData);
        }
    }

    async stakingInfo() {
        const responses = await Service.stakingInfo();
        const respData = responses.data;
        if (respData.returnCode === 0) {
            this.stakingInfoData = {
                apr: respData.apr,
                period: respData.period / 60 / 24,
                depositAddress: respData.depositAddress,
                amount: 0,
                minAmount: respData.minAmount,
            };
            if (this.store.state && this.store.state.address) this.stakingInfoData.amount = await this.getBalanceOf();
        } else {
            switch (respData.returnCode) {
                case 27:
                case 28:
                case 29:
                case 30: {
                    // maybe ticket
                    break;
                }
                default: {
                    let dataObj = {
                        that: this,
                        title: "stakingInfo",
                        msg: respData,
                        callback: "",
                    };
                    this.store.commit("printError", dataObj);
                    break;
                }
            }
        }
    }

    async getBalanceOf() {
        let minABI = [
            {
                constant: true,
                inputs: [
                    {
                        name: "_owner",
                        type: "address",
                    },
                ],
                name: "balanceOf",
                outputs: [
                    {
                        name: "balance",
                        type: "uint256",
                    },
                ],
                payable: false,
                stateMutability: "view",
                type: "function",
            },
            {
                constant: true,
                inputs: [],
                name: "decimals",
                outputs: [
                    {
                        name: "",
                        type: "uint8",
                    },
                ],
                payable: false,
                stateMutability: "view",
                type: "function",
            },
            {
                constant: true,
                inputs: [],
                name: "symbol",
                outputs: [
                    {
                        name: "",
                        type: "string",
                    },
                ],
                payable: false,
                stateMutability: "view",
                type: "function",
            },
            {
                constant: false,
                inputs: [
                    {
                        name: "_to",
                        type: "address",
                    },
                    {
                        name: "_value",
                        type: "uint256",
                    },
                ],
                name: "transfer",
                outputs: [
                    {
                        name: "",
                        type: "bool",
                    },
                ],
                payable: false,
                stateMutability: "nonpayable",
                type: "function",
            },
        ];

        const erc20 = await new ethers.Contract(this.store.state.metaMask.contract, minABI, this.signer);
        const balanceOf = await erc20.balanceOf(this.store.state.address);
        // let decimals = await erc20.decimals();
        // let len = 10 ** Number(decimals);
        return ethers.utils.formatUnits(balanceOf); // balanceOf.div(len.toString()).toString();
    }

    async setMaxStakeNumber(type: any) {
        if (type === "staking") {
            //Staking
            this.stakingAmount = this.setComma(this.stakingInfoData.amount.toString());
            this.numberStakingAmount = Number(this.stakingInfoData.amount);
        } else if (type === "unstaking") {
            //UnStaking
            this.unStakingAmount = this.setComma(this.stakingStateData.withdrawAmount.toString());
            this.numberUnStakingAmount = Number(this.stakingStateData.withdrawAmount);
        }
    }

    listRefresh() {
        this.currentPageNo = 1;
        this.stakingHistory = [];
    }

    async userStaking() {
        const param = {
            address: this.store.state.address,
            id: this.store.state.signature.id,
            sign: this.store.state.signature.sign,
            pageNo: this.currentPageNo,
            pageSize: this.currentPageSize,
            sortType: 0,
            sortingDesc: 0,
            searchText: "",
            lang: "",
        };
        const responses = await Service.userStaking(param);
        const respData = responses.data;
        if (respData.returnCode === 0) {
            respData.list.forEach((item: any, index: any) => {
                const data = item;
                data.remainTimeCount = 0;

                data.sDate = Mixin.getLocalizedDayString(data.startDate);
                data.eDate = Mixin.getLocalizedDayString(data.endDate);

                this.stakingHistory.push(data);
            });
            this.pageCount = respData.page.pageCount;

            if (respData.list && respData.list.length > 0) this.countDownTimer();
        } else {
            // respData.that = this;
            // respData.title = "userStaking";
            // Mixin.responsesManager(respData);
        }
    }

    countDownTimer() {
        this.now += 1000;
        this.stakingHistory.forEach((item, index) => {
            const endDate = Mixin.DateParseForiphone(item.endDate);
            const serverDate = Mixin.DateParseForiphone(item.serverTime);
            const diff = endDate - serverDate;
            const remain = diff - this.now;
            if (remain < 0) {
                this.stakingHistory[index].remainTimeCount = 0;
            } else {
                this.stakingHistory[index].remainTimeCount = remain;
            }
        });
    }

    getRemainTime(remainCount: any) {
        const _second = 1000;
        const _minute = _second * 60;
        const _hour = _minute * 60;
        const _day = _hour * 24;

        if (remainCount <= 0) {
            return this.i18n.t("staking.complete");
        }

        let days = Math.floor(remainCount / _day);
        let hours = Math.floor((remainCount % _day) / _hour);
        let minutes = Math.floor((remainCount % _hour) / _minute);
        let seconds = Math.floor((remainCount % _minute) / _second);

        let remainTime = "";
        if (days > 0) remainTime += days + "d:";
        remainTime += remainTime ? ("" + hours).padStart(2, "0") + "h:" : hours > 0 ? hours + "h:" : "";
        remainTime += remainTime ? ("" + minutes).padStart(2, "0") + "m:" : minutes > 0 ? minutes + "m:" : "";
        remainTime += remainTime ? ("" + seconds).padStart(2, "0") + "s" : seconds > 0 ? seconds + "s" : "-";
        return remainTime;
    }

    moreWaitList() {
        if (this.pageCount > this.currentPageNo) {
            this.currentPageNo++;
            this.userStaking();
        }
    }

    receiveConfirm() {
        if (this.stakingStateData.rewardPoint === 0) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.receive");
            this.popupOkMessage = this.i18n.t("staking.popup_receive_confirm_3");
            this.popupOkCallBack = "";
            return;
        }

        this.popupShow = true;
        this.popupShowHeader = this.i18n.t("staking.popup_receive_confirm_header");
        this.popupShowMessage = this.i18n.t("staking.popup_receive_confirm_message");
        this.popupShowData = "";
        this.popupShowData += "<li>";
        this.popupShowData += "<p>" + this.i18n.t("staking.popup_receive_confirm_1") + "</p>";
        this.popupShowData += "<p><span>" + this.stakingStateData.rewardPoint + "</span> ATT</p>";
        this.popupShowData += "</li>";
        this.popupShowType = "receive";
        this.popupShowCallBack = "";
    }

    stakeConfirm() {
        if (this.numberStakingAmount === 0) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.staking");
            this.popupOkMessage = this.i18n.t("staking.popup_inputAmount");
            this.popupOkCallBack = "";
            return;
        } else if (this.numberStakingAmount < this.stakingInfoData.minAmount) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.staking");
            this.popupOkMessage = this.i18n.t("staking.popup_moreAmount", { amount: this.stakingInfoData.minAmount });
            this.popupOkCallBack = "";
            return;
        }

        this.popupShow = true;
        this.popupShowHeader = this.i18n.t("staking.staking_request");
        this.popupShowMessage = this.i18n.t("staking.popup_stake_confirm");
        this.popupShowData = "";
        this.popupShowData += "<li>";
        this.popupShowData += "<p>" + this.i18n.t("staking.amount") + "</p>";
        this.popupShowData += "<p><span>" + this.stakingAmount + "</span> ATT</p>";
        this.popupShowData += "</li>";
        this.popupShowData += "<li>";
        this.popupShowData += "<p>" + this.i18n.t("staking.apr") + "</p>";
        this.popupShowData += "<p><span class='popup-show-mr'>" + this.stakingInfoData.apr + "</span> %</p>";
        this.popupShowData += "</li>";
        this.popupShowData += "<li>";

        this.popupShowData += "<p>" + this.i18n.t("staking.period") + "</p>";
        if (this.stakingInfoData.period < 1) this.popupShowData += "<p><span>" + this.stakingInfoData.period * 1440 + "</span> Minute</p>";
        else this.popupShowData += "<p><span>" + this.stakingInfoData.period + "</span> Day</p>";

        this.popupShowData += "</li>";
        this.popupShowType = "stake";
        this.popupShowCallBack = "";
    }

    unStakeConfirm() {
        if (this.numberUnStakingAmount === 0) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.unstaking");
            this.popupOkMessage = this.i18n.t("staking.popup_inputAmount");
            this.popupOkCallBack = "";
            return;
        } else if (this.numberUnStakingAmount < this.stakingInfoData.minAmount) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.unstaking");
            this.popupOkMessage = this.i18n.t("staking.popup_moreAmount", { amount: this.stakingInfoData.minAmount });
            this.popupOkCallBack = "";
            return;
        }

        this.popupShow = true;
        this.popupShowHeader = this.i18n.t("staking.unstaking");
        this.popupShowMessage = this.i18n.t("staking.popup_unstaking_confirm_message");
        this.popupShowData = "";
        this.popupShowData += "<li>";
        this.popupShowData += "<p>" + this.i18n.t("staking.popup_unstaking_confirm_1") + "</p>";
        this.popupShowData += "<p><span>" + this.unStakingAmount + "</span> ATT</p>";
        this.popupShowData += "</li>";
        this.popupShowType = "unstaking";
        this.popupShowCallBack = "";
    }

    async receive() {
        const param = {
            address: this.store.state.address,
            signature: this.store.state.signature.signature,
            message: this.store.state.signature.message,
            id: this.store.state.signature.id,
            sign: this.store.state.signature.sign,
            amount: "0",
        };

        const responses = await Service.userTokenWithdrawReward(param);
        const respData = responses.data;
        if (respData.returnCode === 0) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.receive");
            this.popupOkMessage = this.i18n.t("staking.popup_receive_success");
            this.popupOkCallBack = "reload";
        } else {
            // this.popupOkShow = true;
            // this.popupOkHeader = this.i18n.t("staking.receive");
            // this.popupOkMessage = respData.extraMessage;
            // this.popupOkCallBack = "";

            respData.that = this;
            respData.title = this.i18n.t("staking.receive");
            Mixin.responsesManager(respData);
        }
    }

    async staking() {
        try {
            let minABI = [
                {
                    constant: false,
                    inputs: [
                        {
                            name: "_to",
                            type: "address",
                        },
                        {
                            name: "_value",
                            type: "uint256",
                        },
                    ],
                    name: "transfer",
                    outputs: [
                        {
                            name: "",
                            type: "bool",
                        },
                    ],
                    payable: false,
                    stateMutability: "nonpayable",
                    type: "function",
                },
            ];
            const erc20_rw = await new ethers.Contract(this.store.state.metaMask.contract, minABI, this.signer);
            const tx = await erc20_rw.transfer(this.stakingInfoData.depositAddress, ethers.utils.parseUnits(String(this.numberStakingAmount)), {
                gasPrice: 250000000000,
            });
            await tx.wait();

            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.staking");
            this.popupOkMessage = this.i18n.t("staking.popup_staking_success");
            this.popupOkCallBack = "reload";
        } catch (e: any) {
            console.log(e);
            const message = e.data ? e.data.message : e.message;
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.staking");
            this.popupOkMessage = message;
            this.popupOkCallBack = "";
        }
    }

    async unStaking() {
        const param = {
            address: this.store.state.address,
            signature: this.store.state.signature.signature,
            message: this.store.state.signature.message,
            id: this.store.state.signature.id,
            sign: this.store.state.signature.sign,
            amount: this.numberUnStakingAmount,
        };

        const responses = await Service.userTokenWithdrawRequest(param);
        const respData = responses.data;
        if (respData.returnCode === 0) {
            this.popupOkShow = true;
            this.popupOkHeader = this.i18n.t("staking.unstaking");
            this.popupOkMessage = this.i18n.t("staking.popup_unstaking_success");
            this.popupOkCallBack = "reload";
        } else {
            // this.popupOkShow = true;
            // this.popupOkHeader = this.i18n.t("staking.unstaking");
            // this.popupOkMessage = respData.extraMessage;
            // this.popupOkCallBack = "";

            respData.that = this;
            respData.title = this.i18n.t("staking.unstaking");
            Mixin.responsesManager(respData);
        }
    }

    commaAmount(type: any) {
        var x = "";
        var max;
        if (type === "staking") {
            //Staking Amount 입력시
            x = this.stakingAmount.toString();
            max = this.stakingInfoData.amount;
        } else if (type === "unstaking") {
            //UnStaking Amount 입력시
            x = this.unStakingAmount.toString();
            max = this.stakingStateData.withdrawAmount;
        }

        x = this.numberOnlyWithMax(x, max);

        if (type === "staking") {
            //Staking Amount 입력시
            this.numberStakingAmount = Number(x);
            this.stakingAmount = this.setComma(x);
        } else if (type === "unstaking") {
            //UnStaking Amount 입력시
            this.numberUnStakingAmount = Number(x);
            this.unStakingAmount = this.setComma(x);
        }
    }

    numberOnly(numStr: any, isInt = 0) {
        if (isInt) numStr = numStr.replace(/[^0-9]/g, "");
        else {
            numStr = numStr.replace(/[^.0-9]/g, "");
            var decimalNumber = "";
            if (numStr.indexOf(".", 0) >= 0) {
                decimalNumber = numStr.substring(numStr.indexOf(".", 0));
            } else {
                numStr = Number(numStr).toString();
            }

            if (decimalNumber.length > 1) {
                if (Number(decimalNumber.substring(1)) > 0) {
                    numStr = Number(numStr.substring(0, numStr.indexOf("."))).toString();
                    numStr += decimalNumber.substring(0, 5);
                }
            }
        }
        return numStr;
    }

    numberOnlyWithMax(numStr: any, max: any, isInt = 0) {
        numStr = this.numberOnly(numStr, isInt);
        if (max <= Number(numStr)) {
            numStr = max.toString();
        }
        return numStr;
    }

    setComma(obj: any) {
        if (obj === 0) {
            return obj;
        }

        var regx = new RegExp(/(-?\d+)(\d{3})/);
        var bExists = obj.indexOf(".", 0); //0번째부터 .을 찾는다.
        var strArr = obj.split(".");
        while (regx.test(strArr[0])) {
            //문자열에 정규식 특수문자가 포함되어 있는지 체크
            //정수 부분에만 콤마 달기
            strArr[0] = strArr[0].replace(regx, "$1,$2"); //콤마추가하기
        }
        if (bExists > -1) {
            //. 소수점 문자열이 발견되지 않을 경우 -1 반환
            obj = strArr[0] + "." + strArr[1];
        } else {
            //정수만 있을경우 //소수점 문자열 존재하면 양수 반환
            obj = strArr[0];
        }
        return obj; //문자열 반환
    }

    close(popupType: any) {
        switch (popupType) {
            case "PopupShow": {
                this.popupShow = false;
                this.popupShowHeader = "";
                this.popupShowMessage = "";
                this.popupShowData = "";
                this.popupShowType = "";
                if (this.popupShowCallBack === "reload") {
                    location.reload();
                }

                break;
            }
            case "PopupOk": {
                this.popupOkShow = false;
                this.popupOkHeader = "";
                this.popupOkMessage = "";
                if (this.popupOkCallBack === "reload") {
                    location.reload();
                }

                break;
            }
        }
    }

    async request() {
        switch (this.popupShowType) {
            case "receive": {
                this.popupShow = false;
                this.popupShowHeader = "";
                this.popupShowMessage = "";
                this.popupShowData = "";
                this.popupShowType = "";
                this.popupShowCallBack = "";
                this.receive();

                break;
            }
            case "stake": {
                this.popupShow = false;
                this.popupShowHeader = "";
                this.popupShowMessage = "";
                this.popupShowData = "";
                this.popupShowType = "";
                this.popupShowCallBack = "";
                this.staking();

                break;
            }
            case "unstaking": {
                this.popupShow = false;
                this.popupShowHeader = "";
                this.popupShowMessage = "";
                this.popupShowData = "";
                this.popupShowType = "";
                this.popupShowCallBack = "";
                this.unStaking();
            }
        }
    }

    pressedBtn() {
        this.isPressedBtn = true;
    }

    pressedBtnNot() {
        this.isPressedBtn = !this.isPressedBtn;
    }
}
