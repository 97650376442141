import dayjs from "dayjs";

import { store } from "./store";

export default {
    isPc() {
        const ua = window.navigator.userAgent.toString().toLowerCase() || "";
        if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
            return true;
        }
        return false;
    },
    /**
     * 화면에 보여질 때 쓰는 함수
     * @param date
     * @returns
     */
    getLocalizedDateString(date: string) {
        return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getLocalizedDayString(date: string) {
        return dayjs(date).format("YYYY-MM-DD");
    },
    DateParseForiphone(date: string) {
        return dayjs(date).unix() * 1000;
    },
    responsesManager(resp: any) {
        switch (resp.returnCode) {
            case 0:
                break;
            case 27: //
            case 28: // Ticket is expired
            case 29: // Ticket validation failed
            case 30: // Ticket generation failed
            case 274: {
                // member info not
                store.state.emitter.emit("isSignature", {
                    isSignature: true,
                });

                store.commit("clearIntervalTimer");
                break;
            }
            default: {
                store.commit("printError", {
                    that: resp.that,
                    title: resp.title,
                    msg: resp,
                    callback: "",
                });

                store.commit("clearIntervalTimer");
                break;
            }
        }
    },
};
