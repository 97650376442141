<template>
    <div id="popop-show" v-if="initPopupShow">
        <div class="popup-box">
            <h3>{{ initPopupShowHeader }}</h3>
            <p v-html="initPopupShowMessage"></p>
            <ul v-show="initPopupShowData !== ''" v-html="initPopupShowData"></ul>
            <div class="popup-btn-box">
                <button class="btn-hover" @click="closePopup">{{ $t("message.cancel") }}</button>
                <button class="btn-hover" @click="requestPopup">{{ $t("message.ok") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupShow",
    props: {
        initPopupShow: {
            type: Boolean,
            default: false,
        },
        initPopupShowHeader: {
            type: String,
            default: "",
        },
        initPopupShowMessage: {
            type: String,
            default: "",
        },
        initPopupShowData: {
            type: String,
            default: "",
        },
        initPopupShowType: {
            type: String,
            default: "",
        },
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },
        requestPopup() {
            this.$emit("request", this.initPopupShowType);
        },
    },
};
</script>

<style></style>
