import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "popop-show"
}
const _hoisted_2 = { class: "popup-box" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "popup-btn-box ok" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.initIsShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.initPopupOkHeader), 1),
          _createElementVNode("p", { innerHTML: _ctx.initPopupOkMessage }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn-hover",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
            }, _toDisplayString(_ctx.$t("message.ok")), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}