import axios from "axios";
import http from "@/http-common";

class Service {
    /**
     * 안건
     * @param param
     * @returns
     */
    agenda(param: any): Promise<any> {
        return http.post("/agenda", param);
    }

    /**
     * 선택한 안건에 대한 맥스 VP 및 보유 VP
     * @param param
     * @returns
     */
    agendaAdressVp(param: any): Promise<any> {
        return http.post("/agenda/adressvp", param);
    }

    /**
     * 안건 상세
     * @param param
     * @returns
     */
    agendaDetail(param: any): Promise<any> {
        return http.post("/agenda/detail", param);
    }

    /**
     * 투표한 안건
     * @param param
     * @returns
     */
    agendaVote(param: any): Promise<any> {
        return http.post("/agenda/vote", param);
    }

    /**
     * 피쳐드
     * @param NoParam
     * @returns
     */
    banner(param: any): Promise<any> {
        return http.post("/banner", param);
    }

    /**
     * 피쳐드 클릭
     * @param param
     * @returns
     */
    bannerClick(param: any): Promise<any> {
        return http.post("/banner/click", param);
    }

    /**
     * 공지사항
     * @param param
     * @returns
     */
    boardNotice(param: any): Promise<any> {
        return http.post("/board/notice", param);
    }

    /**
     * 현재 안건 카테고리 리스트
     */
    categoryAgenda(): Promise<any> {
        return http.post("/category/agenda");
    }

    /**
     * 카테고리 정보
     */
    categoryInfo(): Promise<any> {
        return http.post("/category/info");
    }

    /**
     * 댓글 삭제
     */
    commentDelete(param: any): Promise<any> {
        return http.post("/community/comment/delete", param);
    }

    /**
     * 댓글 수정
     */
    commentEdit(param: any): Promise<any> {
        return http.post("/community/comment/edit", param);
    }

    /**
     * 댓글 리스트
     */
    commentList(param: any): Promise<any> {
        return http.post("/community/comment/list", param);
    }

    /**
     * 댓글 쓰기
     */
    commentPosted(param: any): Promise<any> {
        return http.post("/community/comment/posted", param);
    }

    /**
     * 커뮤니트 삭제
     */
    communityDelete(param: any): Promise<any> {
        return http.post("/community/delete", param);
    }

    /**
     * 커뮤니트 상세내용
     */
    communityDetail(param: any): Promise<any> {
        return http.post("/community/detail", param);
    }

    /**
     * 커뮤니트 수정
     */
    communityEdit(param: any): Promise<any> {
        return http.post("/community/edit", param);
    }

    /**
     * 게시판 리스트
     */
    communityList(param: any): Promise<any> {
        return http.post("/community/list", param);
    }

    /**
     * 커뮤니트 글쓰기
     */
    communityPosted(param: any): Promise<any> {
        return http.post("/community/posted", param);
    }

    /**
     * 메인 카테고리
     */
    communityCategory(): Promise<any> {
        return http.post("/community/category");
    }

    /**
     * 서브 카테고리
     */
    communityCategorySub(): Promise<any> {
        return http.post("/community/categorysub");
    }

    /**
     * 추천
     */
    communityRecomment(param: any): Promise<any> {
        return http.post("/community/recommend", param);
    }

    /**
     * 신고
     */
    communityReport(param: any): Promise<any> {
        return http.post("/community/report", param);
    }

    /**
     * 파일 업로드
     */
    communityImage(param: any): Promise<any> {
        // /${param.address}/${param.id}/${param.sign}
        return axios.post(`${process.env.VUE_APP_BASE_URL}/community/image`, param, {
            headers: { Pragma: "no-cache", "Cache-Control": "no-cache", "Access-Control-Allow-Origin": "*", "Content-type": "multipart/form-data" },
        });

        // return http.post("/community/image/", param);
    }

    /**
     * 가이드
     */
    guide(param: any): Promise<any> {
        return http.post("/guide", param);
    }

    /**
     * 사이트 설정
     * @param NoParam
     * @returns
     */
    configFront(): Promise<any> {
        return http.post("/config/front");
    }

    /**
     * 메타마스크 클레이튼 연결 정보
     * @param NoParam
     * @returns
     */
    metaMask(): Promise<any> {
        return http.post("/metamask");
    }

    /**
     * 스테이킹 기본 정보
     * @param NoParam
     * @returns
     */
    stakingInfo(): Promise<any> {
        return http.post("/staking/info");
    }

    /**
     * 스테이킹 간략 정보
     * @param NoParam
     * @returns
     */
    stakingSimple(): Promise<any> {
        return http.post("/staking/simple");
    }

    /**
     * 스테이킹 주간 데이터
     * @param NoParam
     * @returns
     */
    stakingWeek(): Promise<any> {
        return http.post("/staking/week");
    }

    /**
     * 스테이킹 금액 출금 요청
     * @param No param
     * @returns
     */
    userTokenWithdrawRequest(param: any): Promise<any> {
        return http.post("/user/token/withdraw/request", param);
    }

    /**
     * 스테이킹 보상액 출금 요청
     * @param No param
     * @returns
     */
    userTokenWithdrawReward(param: any): Promise<any> {
        return http.post("/user/token/withdraw/reward", param);
    }

    /**
     * 번역
     */
    translation(param: any): Promise<any> {
        return http.post("/translation", param);
    }

    /**
     * 메타마스크 사인 지갑 연결
     * @param param
     * @returns
     */
    userSignature(param: any): Promise<any> {
        return http.post("/user/signature", param);
    }

    /**
     * 유저 스테이킹 리스트
     * @param param
     * @returns
     */
    userStaking(param: any): Promise<any> {
        return http.post("/user/staking", param);
    }

    /**
     * 유저 스테이킹 상태값
     * @param param
     * @returns
     */
    userStakingState(param: any): Promise<any> {
        return http.post("/user/staking/state", param);
    }

    /**
     * 유저 티켓 확인
     * @param param
     * @returns
     */
    userTicketCheck(param: any): Promise<any> {
        return http.post("/user/ticket/check", param);
    }

    /**
     * 투표하기
     * @param param
     * @returns
     */
    vote(param: any): Promise<any> {
        return http.post("/vote", param);
    }
}

export default new Service();
