import { createI18n } from "vue-i18n";
import en from "./en.json";
import kr from "./kr.json";

const path = window.location.pathname.split("/");
const lang = "en";
const langs = ["en", "kr"];
if (path[1] !== "") {
    langs.forEach((code) => {
        if (code === path[1]) {
            lang === path[1];
        }
    });
}

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: { en, kr },
    warnHtmlInMessage: "off",
});

export default i18n;
