import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
    // onUploadProgress: function (progressEvent) {
    //
    // },
    // onDownloadProgress: function (progressEvent) {
    //
    // },
});

export default apiClient;
