
import { Options, Vue } from "vue-class-component";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "../store";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import KeyVisual from "@/components/KeyVisual.vue";
import Agenda from "@/components/Agenda.vue";
import Staking from "@/components/Staking.vue";

import Service from "@/services";
import Mixin from "../mixin";
import dayjs from "dayjs";

// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

@Options({
    components: {
        Header,
        Footer,
        KeyVisual,
        Agenda,
        Staking,
        Swiper,
        SwiperSlide,
    },
})
export default class HomeView extends Vue {
    i18n = useI18n();
    router = useRouter();
    store = useStore();

    utc = require("dayjs/plugin/utc");
    setConnected = false;

    options: any[] = [];

    stakingSimpleData: any = {
        totalStaking: 0,
        totalVp: 0,
        apr: 0,
        agendaTotal: 0,
    };
    agendaList: any[] = [];
    guideList: any[] = [];
    guideData: any = {};
    categoryAgendaString = "";
    selectedSubTab: any = 0;

    forumPostList: any[] = [];

    swiperModules = [Autoplay, Pagination];

    changeSubTab(subTabNo: any) {
        this.selectedSubTab = subTabNo;
        this.guideData = this.guideList[subTabNo];
    }

    async created() {
        dayjs.extend(this.utc);
        await this.metaMask();
        await this.stakingSimple();
        // await this.agenda();
        // await this.guide();
        await this.categoryAgenda();
        // await this.forumPosts();
    }

    mounted() {
        this.store.state.emitter.on("changeLanguage", async (r: any) => {
            if (r.isSearched) {
                await this.agenda();
                await this.guide();
                await this.forumPosts();
            }
        });
    }

    /**
     * MataMask Network Info
     */
    async metaMask() {
        const responses = await Service.metaMask();
        if (responses.data.returnCode === 0) {
            this.store.commit("setMetaMask", responses.data.configKlayJoinTokenVOForApi);
        }
    }

    /**
     * Governance Stats

     */
    async stakingSimple() {
        const responses = await Service.stakingSimple();
        if (responses.data.returnCode === 0) {
            this.stakingSimpleData = {
                totalStaking: responses.data.totalStaking,
                totalVp: Math.floor(responses.data.totalVp),
                apr: responses.data.apr,
                agendaTotal: responses.data.agendaTotal,
            };
        }
    }

    /**
     * Active Polls
     */
    async agenda() {
        this.options = [
            { label: this.i18n.t("agenda.All"), value: "" },
            // { label: this.i18n.t("Pending"), value: "0" },
            { label: this.i18n.t("agenda.InProgress"), value: "1" },
            { label: this.i18n.t("agenda.Passed"), value: "2" },
            { label: this.i18n.t("agenda.Rejected"), value: "3" },
            { label: this.i18n.t("agenda.Executed"), value: "4" },
        ];

        const param = {
            pageNo: 1,
            pageSize: 6,
            sortType: 0,
            sortingDesc: 0,
            searchText: "",
            lang: "",
            state: "",
        };

        const responses = await Service.agenda(param);
        if (responses.data.returnCode === 0) {
            this.agendaList = [];
            const results = responses.data.list;
            for (const i in results) {
                const data = results[i];
                data.stateNm = this.options.find((o) => o.value === String(data.state))?.label;
                data.answerVp = data.answer1Vp + data.answer2Vp + data.answer3Vp + data.answer4Vp + data.answer5Vp;
                data.turnout = Math.floor((data.answerVp / data.snapshotVpMax) * 100);
                data.progressItem = [
                    { percent: data.answer1Turnout },
                    { percent: data.answer2Turnout },
                    { percent: data.answer3Turnout },
                    { percent: data.answer4Turnout },
                    { percent: data.answer5Turnout },
                    { percent: 100 - data.turnout },
                ];

                data.quorumItem = [{ percent: data.quorum * 0.69, percent2: data.quorum * 0.975 }];
                data.startDate = dayjs(data.startDate).format("YYYY-MM-DD HH:mm");
                data.endDate = dayjs(data.endDate).format("YYYY-MM-DD HH:mm");

                data.hashtag = "";
                data.tags.split(",").forEach((d: any, i: any) => {
                    if (i < 4) data.hashtag += "<span class='hash'>" + d + "</span>";
                });

                data.posted = dayjs(data.createDate).format("MM DD YYYY HH:mm");
                this.agendaList.push(data);
            }
        }
    }

    async guide() {
        const param = {
            idx: this.localeIdf(),
        };
        const responses = await Service.guide(param);
        if (responses.data.returnCode === 0) {
            this.guideList = [];
            const results = responses.data.list;
            for (const i in results) {
                const data = results[i];
                data.title = Number(i) + 1 + ". " + data.title;
                if (i === "0") this.guideData = data;
                this.guideList.push(data);
            }
        }
    }

    moveGuide() {
        const objGuide = document.getElementById("guide");
        const guideScrollTop = objGuide?.offsetTop;
        window.scroll(0, Number(guideScrollTop));
    }

    agendaDetailPush(data: any) {
        if (this.store.state.isConnected) {
            this.router.push({
                name: "agendadetail",
                query: { idx: data.idx, stateNm: data.stateNm },
            });
        } else {
            this.setConnected = true;
        }
    }

    snapShotBlockNumber(blockNumber: any) {
        window.open(this.store.state.configFront.klayScanUrl + "block/" + blockNumber);
    }

    /**
     * Show me more polls related to
     */
    async categoryAgenda() {
        const responses = await Service.categoryAgenda();
        if (responses.data.returnCode === 0) {
            const list = responses.data.list;
            const length = responses.data.list.length;
            list.forEach((iData: any, i: any) => {
                let classNm = "";
                if (i / 7 === 0) classNm = "one";
                else if (i / 7 === 1) classNm = "two";
                else if (i / 7 === 2) classNm = "three";
                else classNm = "";

                const isInteger = Number.isInteger(i / 7);
                if (isInteger) {
                    if (this.categoryAgendaString !== "") {
                        this.categoryAgendaString += "</div>";
                    }

                    this.categoryAgendaString += "<div class='" + classNm + "'>";
                    this.categoryAgendaString += "<p>" + iData + "</p>";
                } else if (!isInteger) {
                    this.categoryAgendaString += "<p>" + iData + "</p>";
                }

                if (i + 1 === length) {
                    this.categoryAgendaString += "</div>";
                }
            });
        }
    }

    /**
     * Relevant Forum Posts
     */
    async forumPosts() {
        const cdnUrl = this.store.state.configFront.cdnUrl.slice(0, -1);
        const param = {
            idx: this.localeIdf(),
        };
        const responses = await Service.banner(param);
        if (responses.data.returnCode === 0) {
            responses.data.list.forEach((data: any) => {
                data.imgUrl = Mixin.isPc() ? cdnUrl + data.pcFilename : cdnUrl + data.mfilename;
            });

            this.forumPostList = responses.data.list;
        }
    }

    localeIdf() {
        const locale = this.i18n.locale.toString();
        return locale === "en" ? "1" : "2";
    }
}
