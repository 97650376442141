import "balm-ui/dist/balm-ui.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import "@/assets/css/index.css";
import "@/assets/css/main.css";
import "@/assets/css/common.css";
import "@/assets/css/reset.css";
import "@/assets/css/header.css";
import "@/assets/css/footer.css";
import "quill/dist/quill.snow.css";
import "@/assets/css/forum.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import i18n from "./i18n";

import BalmUI from "balm-ui"; // Official Google Material Components
import BalmUIPlus from "balm-ui/dist/balm-ui-plus"; // BalmJS Team Material Components

import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import { VueRecaptcha } from "vue-recaptcha";

import "vue-awesome-paginate/dist/style.css";
import VueAwesomePaginate from "vue-awesome-paginate";

const app = createApp(App);

app.use(store, key);
app.use(router);
app.use(i18n);

app.use(BalmUI); // Mandatory
app.use(BalmUIPlus); // Optional
app.use(VueClipboard);
app.use(VueAwesomePaginate);

app.config.globalProperties.$filters = {
    comma(val: any) {
        let filter_str = "";
        let isMinus = false;
        if (val < 0) {
            isMinus = true;
            val = val.toString().replace("-", "");
        }

        if (val >= 0 && val !== null && val !== undefined) {
            const split_num = val.toString().split(".");
            filter_str = String(split_num[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            if (split_num[1]) {
                filter_str = filter_str + "." + split_num[1];
            }
        }

        if (filter_str === "" || filter_str === null || filter_str === undefined) {
            filter_str = "0";
        }

        if (isMinus) {
            filter_str = "-" + filter_str;
        }

        return filter_str;
    },
    kmb(value: number) {
        if (value === undefined || value === null) return "";
        let returnData = "";

        let commaCnt = 0;
        while (value >= 10000) {
            value /= 1000;
            commaCnt++;
            if (commaCnt === 3) break;
        }

        // returnData = Number(value).toFixed(4);
        returnData = Math.floor(Number(value)).toString();

        if (commaCnt >= 3) {
            returnData += "B";
        } else if (commaCnt >= 2) {
            returnData += "M";
        } else if (commaCnt >= 1) {
            returnData += "K";
        }

        return returnData;
    },
};

app.component("VueRecaptcha", VueRecaptcha);

app.mount("#app");
