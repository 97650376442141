
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import KeyVisual from "@/components/KeyVisual.vue";
import Service from "@/services";
import Mixin from "../mixin";

import { Options, Vue } from "vue-class-component";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "../store";
import dayjs from "dayjs";

@Options({
    components: {
        Header,
        Footer,
        KeyVisual,
    },
})
export default class Agenda extends Vue {
    i18n = useI18n();
    router = useRouter();
    store = useStore();

    agendaList: any[] = [];

    currentPageNo = 1; // 현재 페이지 번호
    currentPageSize = 6; // 한 번에 불러올 게시물 수
    pageCount = 0; // 전체 페이지 수

    isPressedBtn = false;

    votedAgenda = false;
    selected = "";
    options: any[] = [];

    selectToggle = false;
    setConnected = false;

    created() {
        // this.agenda();
    }

    mounted() {
        this.store.state.emitter.on("changeLanguage", async (r: any) => {
            if (r.isSearched) {
                this.listRefresh();
                await this.agenda();
            }
        });

        this.store.state.emitter.on("agendaSearch", async (r: any) => {
            if (r.isSearched) {
                this.listRefresh();
                this.agenda();
            }
        });
    }

    listRefresh() {
        this.currentPageNo = 1;
        this.agendaList = [];
    }

    selectToggleBtn() {
        this.selectToggle = !this.selectToggle;
    }

    stateChange() {
        if (this.votedAgenda && !this.store.state.isConnected) {
            this.votedAgenda = false;
            this.setConnected = true;
            return;
        }

        this.listRefresh();
        this.agenda();
    }

    async agenda() {
        this.options = [
            { label: this.i18n.t("agenda.All"), value: "" },
            // { label: this.i18n.t("Pending"), value: "0" },
            { label: this.i18n.t("agenda.InProgress"), value: "1" },
            { label: this.i18n.t("agenda.Passed"), value: "2" },
            { label: this.i18n.t("agenda.Rejected"), value: "3" },
            { label: this.i18n.t("agenda.Executed"), value: "4" },
        ];

        let responses = null;
        if (this.votedAgenda) {
            const param = {
                address: this.store.state.address,
                id: this.store.state.signature.id,
                sign: this.store.state.signature.sign,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sortType: 0,
                sortingDesc: 0,
                searchText: "",
                lang: "",
                state: this.selected,
            };

            responses = await Service.agendaVote(param);
        } else {
            const param = {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sortType: 0,
                sortingDesc: 0,
                searchText: "",
                lang: "",
                state: this.selected,
            };

            responses = await Service.agenda(param);
        }

        if (responses.data.returnCode === 0) {
            const results = responses.data.list;
            for (const i in results) {
                const data = this.votedAgenda ? results[i].agendaVOForApi : results[i];
                data.stateNm = this.options.find((o) => o.value === String(data.state))?.label;
                data.answerVp = data.answer1Vp + data.answer2Vp + data.answer3Vp + data.answer4Vp + data.answer5Vp;
                data.turnout = Math.floor((data.answerVp / data.snapshotVpMax) * 100);
                data.progressItem = [
                    { percent: data.answer1Turnout },
                    { percent: data.answer2Turnout },
                    { percent: data.answer3Turnout },
                    { percent: data.answer4Turnout },
                    { percent: data.answer5Turnout },
                    { percent: 100 - data.turnout },
                ];

                data.quorumItem = [{ percent: data.quorum * 0.69, percent2: data.quorum * 0.975 }];
                data.startDate = dayjs(data.startDate).format("YYYY-MM-DD HH:mm");
                data.endDate = dayjs(data.endDate).format("YYYY-MM-DD HH:mm");

                data.hashtag = "";
                data.tags.split(",").forEach((d: any, i: any) => {
                    if (i < 4) data.hashtag += "<span class='hash'>" + d + "</span>";
                });
                data.hashtag += data.tags.split(",").length > 4 ? "" : "";

                data.posted = dayjs(data.createDate).format("MM DD YYYY HH:mm");

                this.agendaList.push(data);
            }

            this.pageCount = responses.data.page.pageCount;
        } else {
            if (this.votedAgenda) {
                responses.data.that = this;
                responses.data.title = this.i18n.t("agenda.vote");
                Mixin.responsesManager(responses.data);
            }
        }
    }

    moreWaitList() {
        if (this.pageCount > this.currentPageNo) {
            this.currentPageNo++;
            this.agenda();
        }
    }

    agendaDetailPush(data: any) {
        if (this.store.state.isConnected) {
            this.router.push({
                name: "agendadetail",
                query: { idx: data.idx, stateNm: data.stateNm },
            });
        } else {
            this.setConnected = true;
        }
    }

    snapShotBlockNumber(blockNumber: any) {
        window.open(this.store.state.configFront.klayScanUrl + "block/" + blockNumber);
    }

    pressedBtn() {
        this.isPressedBtn = true;
    }

    pressedBtnNot() {
        this.isPressedBtn = !this.isPressedBtn;
    }
}
