import { createStore, useStore as baseUseStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import mitt, { Emitter, EventType } from "mitt";
import Service from "@/services";
import Mixin from "../mixin";
import { InjectionKey } from "vue";

export interface State {
    isLanguage: string | { title: string; code: string };
    isTestPopup: boolean;
    isTestConsole: boolean;
    isConnected: boolean;
    configFront: {
        authKey: string;
        bscScanUrl: string;
        cdnUrl: string;
        ethScanUrl: string;
        klayScanUrl: string;
        niceUrl: string;
        pinataScanUrl: string;
        servertime: string;
        terms1: string;
        terms2: string;
        terms3: string;
        ticketTime: number;
        captchaKey: string;
    };
    keyVisualUrl: string;
    address: string;
    id: string;
    ticket: string;
    metaMask: {
        chainId: string;
        contract: string;
        networkName: string;
        scanEndpoint: string;
        symbol: string;
        url: string;
    };
    signature: {
        signature: string;
        message: string;
        id: string;
        sign: string;
    };
    emitter: Emitter<Record<EventType, unknown>>;
    reloadFlag: boolean;
    ticketCheck: ReturnType<typeof setInterval> | undefined;
    translation: {
        en: object;
        kr: object;
    };
    translationVersion: number;
}
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    plugins: [
        createPersistedState({
            paths: ["isLanguage", "isConnected", "configFront", "keyVisualUrl", "address", "id", "ticket", "metaMask", "signature", "emitter", "reloadFlag", "translationVersion", "translation"],
        }),
    ],
    state: {
        isLanguage: "",
        isTestPopup: false,
        isTestConsole: false,
        isConnected: false,
        configFront: {
            authKey: "",
            bscScanUrl: "",
            cdnUrl: "",
            ethScanUrl: "",
            klayScanUrl: "",
            niceUrl: "",
            pinataScanUrl: "",
            servertime: "",
            terms1: "",
            terms2: "",
            terms3: "",
            ticketTime: 0,
            captchaKey: "",
        },
        keyVisualUrl: "",
        address: "",
        id: "",
        ticket: "",
        metaMask: {
            chainId: "",
            contract: "",
            networkName: "",
            scanEndpoint: "",
            symbol: "",
            url: "",
        },
        signature: {
            signature: "",
            message: "",
            id: "",
            sign: "",
        },
        emitter: mitt(),
        reloadFlag: false,
        ticketCheck: undefined,
        translationVersion: 0,
        translation: {
            en: {},
            kr: {},
        },
    },
    getters: {
        getAddressEllipsis(state) {
            const ellipsis = state.address.toString().substring(0, 5) + "..." + state.address.toString().substring(state.address.toString().length - 4);
            return state.isConnected ? ellipsis : "";
        },
    },
    mutations: {
        setLanguage: function (state, isLanguage) {
            state.isLanguage = isLanguage;
        },
        setConnected: function (state, isConnected) {
            state.isConnected = isConnected;
        },
        setConfigFront: function (state, configFront) {
            state.configFront = configFront;
        },
        setKeyVisualUrl: function (state, keyVisualUrl) {
            state.keyVisualUrl = keyVisualUrl;
        },
        setAddress: function (state, address) {
            state.address = address.toLowerCase();
            state.isConnected = address === "" ? false : true;
        },
        setId: function (state, id) {
            state.id = id;
        },
        setTicket: function (state, ticket) {
            state.ticket = ticket;
        },
        setMetaMask: function (state, metaMask) {
            state.metaMask = metaMask;
        },
        setSignature: function (state, signature) {
            state.signature = signature;
        },
        setReloadFlag: function (state, reloadFlag) {
            state.reloadFlag = reloadFlag;
        },
        setTranslation: function (state, data) {
            state.translationVersion = data.version;
            state.translation = data.data;
        },
        setAllReset: function (state) {
            state.isLanguage = "";
            state.isConnected = false;
            // state.configFront = {
            //     authKey: "",
            //     bscScanUrl: "",
            //     cdnUrl: "",
            //     ethScanUrl: "",
            //     klayScanUrl: "",
            //     niceUrl: "",
            //     pinataScanUrl: "",
            //     servertime: "",
            //     terms1: "",
            //     terms2: "",
            //     terms3: "",
            //     ticketTime: 0,
            // };
            state.keyVisualUrl = "";
            state.address = "";
            state.id = "";
            state.ticket = "";
            // state.metaMask = {
            //     chainId: "",
            //     contract: "",
            //     networkName: "",
            //     scanEndpoint: "",
            //     symbol: "",
            //     url: "",
            // };
            state.signature = {
                signature: "",
                message: "",
                id: "",
                sign: "",
            };
            // state.emitter.all.clear();
            state.reloadFlag = false;
            state.ticketCheck = undefined;
        },
        printError: function (state, obj) {
            try {
                let message = "";
                if (typeof obj.msg === "string") message = obj.msg;
                else if (typeof obj.msg === "object") message = obj.msg.description; //JSON.stringify(obj.msg);

                //obj.that.popupOkCallBack = obj.callback;

                if (state.isTestPopup) {
                    obj.that.popupOkShow = true;
                    obj.that.popupOkHeader = obj.title;
                    obj.that.popupOkMessage = message;
                }

                if (state.isTestConsole) {
                    console.error(obj.title);
                    console.error(message);
                }
            } catch (e) {
                console.error(e);
                console.error(obj);
            }
        },
        setIntervalTimer(state) {
            state.ticketCheck = setInterval(async () => {
                if (state.signature.id !== "") {
                    const responses = await Service.userStakingState({
                        address: state.address,
                        id: state.signature.id,
                        sign: state.signature.sign,
                    });
                    Mixin.responsesManager(responses.data);
                }
            }, (state.configFront.ticketTime - 10) * 1000);
        },
        clearIntervalTimer(state) {
            if (state.ticketCheck) {
                clearInterval(state.ticketCheck);
                state.ticketCheck = undefined;
            }
        },
    },
    actions: {},
    modules: {},
});

export function useStore() {
    return baseUseStore(key);
}
