
import { defineComponent } from "vue";

export default defineComponent({
    name: "PopupOk",
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initPopupOkHeader: {
            type: String,
            default: "",
        },
        initPopupOkMessage: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isPressedBtn: false,
        };
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },
    },
    pressedBtn() {
        this.isPressedBtn = true;
    },
    pressedBtnNot() {
        this.isPressedBtn = !this.isPressedBtn;
    },
});
